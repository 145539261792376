import React from "react";
import styled from "styled-components";
import BlogPost from "./BlogPost";
// import MediaIsMobile from "./MediaIsMobile";

const MediaIsMobile = ({ children }) => children(false);

const PaddedList = styled.ul`
  padding: 0 1em;
  margin: 0;

  &:hover article {
    opacity: 0.4;
  }
`;

export const PostList = ({ posts }) => (
  <PaddedList>
    {posts.map(post => (
      <BlogPost {...post} key={post.link} />
    ))}
  </PaddedList>
);

export default PostList;

import React from "react";
import { Article, PostTitle, PostFooter, Link } from "./PostDesign";

export default ({ title, date, description, link }) => (
  <Link to={link}>
    <Article>
      <PostTitle dir="auto">{title}</PostTitle>
      <p dir="auto">{description}</p>
      <PostFooter dir="auto">Published on {date}</PostFooter>
    </Article>
  </Link>
);

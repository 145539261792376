import styled from "styled-components";
import { Link as UnstyledLink } from "gatsby";

export const RED = "#cf3232";
export const BRIGHT_RED = "#c79e9e";

export const Link = styled(UnstyledLink)`
  text-decoration: none;
  color: #333;

  & h1 {
    color: ${RED};
    margin: 0;
    margin-bottom: 0.25em;
    padding: 0;
    font-family: "Assistant", serif;
  }

  & p {
    font-family: "Assistant", serif;
  }
`;

export const Article = styled.article`
  padding: 2em 0;
  line-height: 1.4;
  transition: all 0.2s ease;

  &:hover {
    opacity: 1 !important;
  }
`;

export const PostTitle = styled.h1`
  font-family: "Assistant", Helvetica Neue, Helvetica, Arial;
`;

export const PostFooter = styled.small`
  margin-top: -1em;
  font-family: "Assistant", Helvetica Neue, Helvetica, Arial;
  display: block;
`;

import React from "react";
import { graphql } from "gatsby";

import PostList from "../components/PostList";
import Layout from "../components/Layout";

const IndexPage = ({ data }) => (
  <Layout>
    <PostList
      posts={data.allMarkdownRemark.edges.map(({ node }) => {
        const languageHint = node.frontmatter.lang === "he" ? " (Hebrew)" : "";
        const title = node.frontmatter.title || node.fields.slug;

        return {
          title: title + languageHint,
          link: node.fields.slug,
          description: node.frontmatter.subtitle,
          date: node.frontmatter.date
        };
      })}
    />
  </Layout>
);

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { hide_from_homepage: { ne: true } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            subtitle
            lang
          }
        }
      }
    }
  }
`;
